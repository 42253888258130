import React from "react";
/** @jsx jsx */
import { Box, jsx, useThemeUI } from "theme-ui";
import {
  Container,
  Flex,
  FlexGrid,
  Heading,
  Text,
  AspectRatio,
} from "../../components/core";
import Page from "../../components/Page";
import Contact from "../../components/Contact";
import { Parallax } from "../../components/animate";
import { alpha } from "@theme-ui/color";
import { motion } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import { CardContent } from "../../components/event/CardContent";
import { Card } from "../../components/Card";
import { Vague } from "../../components/svg";
import { useResponsiveValue } from "../../hooks/breakpoints";
import { InView } from "react-intersection-observer";
import SEO from "../../components/SEO";
import { getImage } from "gatsby-plugin-image";

const queryData = graphql`
  query EventsQueryData {
    markdownRemark(fields: { slug: { eq: "/evenements/" } }) {
      id
      frontmatter {
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        intro {
          text
          title
        }
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: { templateKey: { eq: "event" }, visible: { eq: true } }
      }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            numero
            title
            place
            templateKey
            type
            date(formatString: "DD MMMM YYYY", locale: "fr")
            image {
              childImageSharp {
                gatsbyImageData(
                  placeholder: NONE
                  layout: CONSTRAINED
                  aspectRatio: 1.666
                  width: 900
                )
              }
            }
          }
        }
      }
    }
  }
`;
const Events = () => {
  const data = useStaticQuery(queryData);
  const { edges: events } = data.allMarkdownRemark;
  const frontmatter = data.markdownRemark.frontmatter;
  const { theme } = useThemeUI();
  const value = useResponsiveValue([12, 6, 4]);
  const variants = {
    visible: (i) => ({
      opacity: 1,
      transition: {
        delay: (i % (12 / value)) * 0.3,
      },
    }),
    hidden: { opacity: 0 },
  };

  const totalEvents = [
    ...events.filter(
      ({ node: event }) => event.frontmatter.type === "Permanent"
    ),
    ...events.filter(
      ({ node: event }) => event.frontmatter.type === "Ponctuel"
    ),
  ];

  return (
    <Page sx={{ backgroundColor: "background3" }}>
      <SEO
        title="Les événements"
        description="Retrouvez l'ensemble des activités de Clean my Calanques !"
      />
      <AspectRatio ratio={[1440 / 630, 1440 / 480, 1440 / 315]}>
        <Parallax
          alt="Image en-tête évenements"
          image={getImage(frontmatter.image)}
          backgroundColor={alpha("primary", 0.25)(theme)}
        >
          <Flex
            sx={{
              width: "100%",
              height: "100%",
              py: 0,
              px: [2, null, 0],
            }}
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1.6, delay: 0.8 }}
              sx={{ textAlign: "center" }}
            >
              <Heading as="h1" color="white">
                Ramassages
              </Heading>
            </motion.div>
          </Flex>
        </Parallax>
      </AspectRatio>
      <Container sx={{ paddingTop: [8, null, 10] }}>
        <Box>
          <Heading as="h2" color="secondary" sx={{ marginBottom: 3 }}>
            {frontmatter.intro.title}
          </Heading>
          <Text>{frontmatter.intro.text}</Text>
          <Vague
            color="primary"
            sx={{
              marginTop: 6,
              marginBottom: [8, null, 11],
            }}
          />
        </Box>
      </Container>
      <Container sx={{ paddingBottom: [8, null, 13] }} variant="containerLg">
        {totalEvents && (
          <FlexGrid positions={["flex-start", "stretch"]} columns={[12, 6, 4]}>
            {totalEvents.map(({ node: event }, index) => (
              <Box key={event.id}>
                <InView triggerOnce threshold={0.3}>
                  {({ inView, ref }) => (
                    <Card
                      ref={ref}
                      id={event.frontmatter.numero}
                      custom={index}
                      variants={variants}
                      animate={inView ? "visible" : "hidden"}
                      to={event.fields.slug}
                    >
                      <Card.Image
                        image={getImage(event.frontmatter.image)}
                        imageLoading={index < 6 ? "eager" : "lazy"}
                        alt={`Image card ${event.frontmatter.title}`}
                      />
                      <CardContent
                        numero={event.frontmatter.numero}
                        type={event.frontmatter.type}
                        date={event.frontmatter.date}
                        title={event.frontmatter.title}
                        place={event.frontmatter.place}
                      />
                    </Card>
                  )}
                </InView>
              </Box>
            ))}
          </FlexGrid>
        )}
      </Container>
      <Contact />
    </Page>
  );
};

export default Events;
